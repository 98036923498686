import states from './style-accordion-modifier.states.js'

// Components
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'
import SliderModifier from '../slider-modifier/Slider-modifier.vue'
import PaddingModifier from '../padding-modifier/Padding-modifier.vue'

// Libraries
import {
  merge as _merge
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Style-mj-accordion'

// Vue@Properties
const props = {
  mjml: Object
}
// Vue@Properties

// Vue@subComponents
const components = {
  SliderModifier,
  PaddingModifier,
  custColorPicker
}

// Vue@data
const data = function () {
  return {
    states
  }
}
// Vue@data

const computed = {
  // Titles
  titleFont: {
    get () {
      return this.mjml.children[0].children[0].attributes['font-family']
    },
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].children[0].attributes, 'font-family', newValue)
      }
    }
  },
  titleSize: {
    get () {
      return parseInt(this.mjml.children[0].children[0].attributes['font-size'], 10)
    },
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].children[0].attributes, 'font-size', `${newValue}px`)
      }
    }
  },
  titleColor: {
    get () {
      return this.mjml.children[0].children[0].attributes.color
    },
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].children[0].attributes, 'color', newValue)
      }
    }
  },
  titleBg: {
    get () {
      return this.mjml.children[0].children[0].attributes['background-color']
    },
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].children[0].attributes, 'background-color', newValue)
      }
    }
  },

  // texts
  textFont: {
    get () {
      return this.mjml.children[0].children[1].attributes['font-family']
    },
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].children[1].attributes, 'font-family', newValue)
      }
    }
  },
  textSize: {
    get () {
      return parseInt(this.mjml.children[0].children[1].attributes['font-size'], 10)
    },
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].children[1].attributes, 'font-size', `${newValue}px`)
      }
    }
  },
  textColor: {
    get () {
      return this.mjml.children[0].children[1].attributes.color
    },
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].children[1].attributes, 'color', newValue)
      }
    }
  },
  textBg: {
    get () {
      return this.mjml.children[0].children[1].attributes['background-color']
    },
    set (newValue) {
      for (const i in this.mjml.children) {
        this.$set(this.mjml.children[i].children[1].attributes, 'background-color', newValue)
      }
    }
  },
  // Func@lineHeight
  /**
   * Text line-height
   * @return {Integer}
   */
  lineHeight () {
    return parseFloat(this.mjml.children[0].children[1].attributes['line-height'])
  }
  // Func@lineHeight
}

const methods = {

  // Func@onLineHeightChanged
  /**
   * Update the `line-height` attribute with the new value
   * @param {Float} value
   */
  onLineHeightChanged (value) {
    for (const i in this.mjml.children) {
      this.mjml.children[i].children[1].attributes['line-height'] = `${value}`
    }
  },
  // Func@onLineHeightChanged

  /**
   * Generate quote for composed font to avoid
   * MJML issue as "Arial Black" becoming "Arial #000"
   * @param {String} fonts (Font list as "Arial Black, Arial-Black, Arial")
   */
  quoteComposedFontName (fonts) {
    return fonts.split(',').map(f => {
      const isComposedName = f.trim().split(' ').length > 1
      return isComposedName ? `'${f.trim()}'` : f.trim()
    }).join()
  },

  updateTitleColor (val) {
    this.titleColor = val
    for (const i in this.mjml.children) {
      this.mjml.children[i].children[0].attributes.color = val
    }
  },
  updateTextColor (val) {
    this.textColor = val
    for (const i in this.mjml.children) {
      this.mjml.children[i].children[1].attributes.color = val
    }
  },
  updateTitleBg (val) {
    this.titleBg = val
    for (const i in this.mjml.children) {
      this.mjml.children[i].children[0].attributes['background-color'] = val
    }
  },
  updateTextBg (val) {
    this.textBg = val
    for (const i in this.mjml.children) {
      this.mjml.children[i].children[1].attributes['background-color'] = val
    }
  },

  // Func@updateContainerColor
  /**
   * Update container color on editor
   * @param  {String} val (hexa)
   */
  updateContainerColor (val) {
    this.mjml.attributes['container-background-color'] = val
  }
  // Func@updateContainerColor
}

const watch = {
  'states.arrowcolor': {
    handler (color) {
      if (color === 'black') {
        this.mjml.attributes['icon-wrapped-url'] = 'https://s3-eu-west-1.amazonaws.com/www.snblymm.com/1MSending/Images/accordion/chevron-right.png'
        this.mjml.attributes['icon-unwrapped-url'] = 'https://s3-eu-west-1.amazonaws.com/www.snblymm.com/1MSending/Images/accordion/chevron-down.png'
      } else {
        this.mjml.attributes['icon-wrapped-url'] = 'https://s3-eu-west-1.amazonaws.com/www.snblymm.com/1MSending/Images/accordion/chevron-white-right.png'
        this.mjml.attributes['icon-unwrapped-url'] = 'https://s3-eu-west-1.amazonaws.com/www.snblymm.com/1MSending/Images/accordion/chevron-white-down.png'
      }
    }
  },
  'states.paddingTitle': {
    handler (stateTitleMjml) {
      this.mjml.children.forEach(element => {
        const title = element.children.find(c => c.tagName === 'mj-accordion-title')
        _merge(title, stateTitleMjml)
      })
    },
    deep: true
  },
  'states.paddingText': {
    handler (stateTextMjml) {
      this.mjml.children.forEach(element => {
        const text = element.children.find(c => c.tagName === 'mj-accordion-text')
        _merge(text, stateTextMjml)
      })
    },
    deep: true
  }
}

// Vue component syntax
export default {
  name,
  props,
  methods,
  computed,
  components,
  data,
  watch
}
