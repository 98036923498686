// Configs
import mjAccordionTitle from '@/assets/config/mjml-components/mj-accordion-title.conf'
import mjAccordionText from '@/assets/config/mjml-components/mj-accordion-text.conf'
import textDefaultStyle from '@/assets/config/default-style/text.conf'
import userConfig from '@/assets/config/user/user.conf'

// Libraries
import {
  orderBy as _orderBy
} from 'lodash-es'

const fonts = _orderBy([
  ...userConfig.fonts.map(f => f.name.includes('-') ? `${f.name.replaceAll('-', ' ')}, ${f.name}, arial` : `${f.name}, arial`),
  ...textDefaultStyle.fonts.imported.map(f => {
    const fallback = f.custom_fallback ? f.custom_fallback : 'arial'
    return f.name.includes('-') ? `${f.name.replaceAll('-', ' ')}, ${f.name}, ${fallback}` : `${f.name}, ${fallback}`
  }),
  ...textDefaultStyle.fonts.native
])

export default {
  revertReadingDirection: false,
  arrowcolor: 'black',
  fontFamily: {
    options: [
      'default',
      ...fonts
    ]
  },
  paddingTitle: {
    attributes: {
      padding: mjAccordionTitle.attributes.default.padding
    }
  },
  paddingText: {
    attributes: {
      padding: mjAccordionText.attributes.default.padding
    }
  }
}
